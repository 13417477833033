*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root{
    /* --blue: #0082e6 */
    /* --blue: #438543 culoare veche in branding si marjketing*/
    --green:#085c2b;
    /* --greenHover:#0d6e35; alternative of green when hovering buttons*/ 
    --greenHover: #0a7d39


}

@tailwind base;
@tailwind components;
@tailwind utilities;
